
import { serviceProvider } from './serviceProvider'

export const getPublicMedicsFromSearch = (data) => serviceProvider.get('/public/getMedicsFromSearch/'+data.category+'/'+data.specialty+'/'+data.county+'/'+(data.rumu?1:0),data);
export const getPublicMedicsByName = (data) => serviceProvider.get('/public/getMedicsByName/'+data.name,data);
export const getPublicMedicsByCondition = (data) => serviceProvider.get('/public/getMedicsByCondition/'+data.condition,data);
export const sendRequest = (data) => serviceProvider.post('/public/sendRequest',data, {headers: {
    'Content-Type': 'multipart/form-data'
  }});
export const sendRumuRequest = (data) => serviceProvider.post('/m5/sendRequest',data, {headers: {
    'Content-Type': 'multipart/form-data'
  }});
export const getMedicTypes = (data) => serviceProvider.get('/public/getMedicTypes',{headers: { Authorization: `Bearer ${data.token}` }});
export const getMedicProfile = (data) => serviceProvider.get('/public/getMedicProfile/'+data.medicId,{});

